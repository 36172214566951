import {
  Box,
  Button, IconButton,
  Heading,
  Text,
  Image,
  VStack,
  HStack,
  useColorModeValue,
  Flex,
  Collapse, useDisclosure, Link,
} from '@chakra-ui/react'
import {
  HamburgerIcon,
  CloseIcon,
} from '@chakra-ui/icons';
import { ColorModeSwitcher } from '../ColorModeSwitcher'
import React, { useContext } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import logoColor from '../assets/logo/WakeAppLogoV1.png'
import { AppContext } from '../AppProvider'

function AppContainer () {
  const { isOpen, onToggle } = useDisclosure();

  const {bgColor, color} = useContext(AppContext)

  function getNavItems() {
    return [
      {
        label: "Contact",
        href: "contact",
      },
      {
        label: "Privacy",
        href: "privacy-policy",
      },
      {
        label: "Terms",
        href: "terms",
      }
    ]
  }

  return (
    <Box
      textAlign="center"  minH="100vh"
      flexDir={"column"} overflowX={'hidden'} minWidth={'320px'}
      display={'flex'}
      bgColor={bgColor}
      color={"white"}
    >
      <Flex
        display={'flex'}
        bg={bgColor}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
        justify={'space-between'}
        zIndex={10}
      >
        <WakeCallLogo/>


        {/* Desktop */}
        <HStack spacing={6} display={{base: 'none', lg: 'flex'}}>
          {getNavItems().map((navItem) => (
            <Link href={navItem.href}>
              {navItem.label}
            </Link>
          ))}
        </HStack>


        <Flex
          ml={{ base: -2 }}
          display={{ base: 'flex', lg: 'none' }}>
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
      </Flex>

      <Collapse
        in={isOpen} animateOpacity
        display={'block'}
      >
        <VStack
          pt={6}
          px={5}
          py={5}
          spacing={6}
          borderBottom={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.900')}
          align={'start'}
          display={{base: 'flex', lg: 'none' }}
        >
          {getNavItems().map((navItem) => (
            <Link href={navItem.href}>
              {navItem.label}
            </Link>
          ))}
        </VStack>
      </Collapse>

      <Outlet/>


    </Box>
  )
}

export function WakeCallLogo() {
  const navigate = useNavigate()
  return (
    <HStack spacing={2} align={"center"} onClick={() => navigate('/')} cursor={'pointer'}>
      <WakeCallLogoIcon />
      <VStack spacing={0} align={"start"} onClick={() => navigate('/')} cursor={'pointer'}>
        <HStack>
          <Heading size={'lg'}>WakeApp</Heading>
          {/*<Tag size={'md'} bg={'gray.500'} color={'white'} variant='solid'>*/}
          {/*  Beta*/}
          {/*</Tag>*/}
        </HStack>

        <Text fontSize={'xs'} textAlign={"left"} color={useColorModeValue("gray.600", "gray.500")}>
          Waking you every morning.
        </Text>
      </VStack>
    </HStack>

  )
}

export function WakeCallLogoIcon(props) {
  return (
    <Image src={logoColor} height={'40px'} width={'auto'} borderRadius={'8px'} {...props}/>
  )
}

export default AppContainer
