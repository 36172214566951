import {
  Box,
  Container,
  Heading,
  VStack, Stack, Text,
  UnorderedList, ListItem, Link,
} from '@chakra-ui/react'
import React from 'react';
import { Helmet } from 'react-helmet'

function PrivacyPolicyPage() {
  return (
    <VStack spacing={8} flex={1}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WakeApp | Contact</title>
      </Helmet>
      {/* <Flex backgroundImage={null} bgSize={"cover"} height={"40vh"} */}
      {/*           display={"flex"} flex={1} */}
      {/*      minHeight={"40vh"} width={"100%"} */}
      {/* > */}
      {/* </Flex> */}
      <Container maxW="3xl" position="relative">

        <Stack
          as={Box}
          textAlign="start"
          spacing={{ base: 8, md: 14 }}
          pt={{ base: 20, md: 36 }}
          pb={{ base: 20 }}
          zIndex={1}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight="110%"
            zIndex={1}
          >
            Get In Touch
          </Heading>
        </Stack>
        <Stack spacing={6} align="start" textAlign="start">

          <Text>
            For any questions or queries feel free to get in touch via email. Just send us a message <Link color={'blue.300'} href="mailto:support@wakeapp.ai">support@wakeapp.ai</Link>.
          </Text>
          <Text>
            We’re here to help and would love to hear from you! Whether you have questions, feedback, or need assistance, our team is ready to support you. Feel free to reach out to us via email at <a href="mailto:support@wakeapp.ai">support@wakeapp.ai</a>.
            <br/>
            Here are some of the things you can contact us about:
          </Text>

          <UnorderedList>
            <ListItem><b>Feature Requests:</b> Have an idea for a new feature or improvement? Let us know!</ListItem>
            <ListItem><b>Issues or Bugs:</b> Encountered a problem while using the app? We’re here to fix it.</ListItem>
            <ListItem><b>General Questions:</b> Curious about how something works? Just ask..</ListItem>
            <ListItem><b>Account or Settings Help:</b> Need assistance managing your account or customizing settings?</ListItem>
            <ListItem><b>Feedback:</b> Encountered a problem while using the app? We’re here to fix it.</ListItem>
          </UnorderedList>

          <Text>
            We’re committed to providing you with the best experience possible. Drop us a message anytime, and we’ll get back to you as quickly as we can. We look forward to hearing from you!
          </Text>




        </Stack>
      </Container>

    </VStack>
  );
}

export default PrivacyPolicyPage;
