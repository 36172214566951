import { createContext } from 'react'
import { useColorModeValue } from '@chakra-ui/react'

export const AppContext = createContext({
  bgColor: 'gray.800',
})

export function AppProvider (props) {

  return (
    <AppContext.Provider value={{
      bgColor: 'gray.800',
    }}>
      {props.children}
    </AppContext.Provider>
  )
}
